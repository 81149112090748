import {gql} from '@apollo/client'
import {GraphQL} from '../lib/GraphQL'
import {LanguageCodes, TextDirection} from '../lib/LanguageCodes'

export interface Language {
    flag: string
    isoCode: LanguageCodes
    isoLanguageName: string
    nativeLanguageName: string
    textDirection: TextDirection
    translatedLanguageName?: string
}

export interface LanguagesRequest {
    languageCode?: LanguageCodes
}

export interface LanguagesResponse {
    languages: Language[]
}

const getLanguages = gql`
    query Languages($languageCode: LanguageCodes) {
        Languages(languageCode: $languageCode) {
            languages {
                nativeLanguageName
                flag
                isoCode
                isoLanguageName
                textDirection
                translatedLanguageName
            }
        }
    }
`

export class Languages {
    static async withTranslation(variables: LanguagesRequest): Promise<LanguagesResponse> {
        const response = await GraphQL.client.query<
            {Languages: LanguagesResponse},
            LanguagesRequest
        >({
            query: getLanguages,
            variables
        })
        if (response.errors !== undefined) {
            throw new Error(response.errors.join(', '))
        }
        return response.data.Languages
    }
}
