import React from 'react'
import {BrowserRouter as Router, Navigate, Route, Routes} from 'react-router-dom'
import {Contact} from './pages/contact'
import {Legal} from './pages/legal'
import {Main} from './pages/main'

function App(): JSX.Element {
  return (
    <Router>
      <Routes>
        <Route caseSensitive={false} path="/:language/contact" element={<Contact />} />
        <Route caseSensitive={false} path="/:language/legal" element={<Legal />} />
        <Route path="/contact" element={<ContactRedirect />} />
        <Route path="/legal" element={<LegalRedirect />} />
        <Route caseSensitive={false} path="/:language" element={<Main />} />
        <Route path="/" element={<LanguageRedirect />} />
      </Routes>
    </Router>
  )
}

export function fetchBrowserLanguage(): string {
  let language = navigator.language
  if (
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    language === undefined &&
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    navigator.languages !== undefined &&
    navigator.languages[0] !== undefined
  ) {
    language = navigator.languages[0]
  }
  return language.substr(0, 2)
}

function LanguageRedirect(): JSX.Element {
  return <Navigate to={`/${fetchBrowserLanguage().toLowerCase()}`} />
}

function ContactRedirect(): JSX.Element {
  return <Navigate to={`/${fetchBrowserLanguage().toLowerCase()}/contact`} />
}

function LegalRedirect(): JSX.Element {
  return <Navigate to={`/${fetchBrowserLanguage().toLowerCase()}/legal`} />
}

export default App
