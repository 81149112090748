import LocationOnIcon from '@mui/icons-material/LocationOn'
import {
  Autocomplete,
  Button,
  CircularProgress,
  Container,
  Paper,
  TextField,
  Typography
} from '@mui/material'
import React, {SyntheticEvent} from 'react'
import {CityForCoordinatesResponse} from '../client/CityForCoordinates'
import {FindCity, FindCityResponse} from '../client/FindCity'
import {StringsResponse} from '../client/Strings'
import {LanguageCodes} from '../lib/LanguageCodes'

interface Props {
  language: LanguageCodes
  location?: CityForCoordinatesResponse
  strings?: StringsResponse
  setError: (args?: {message?: string; title?: string}) => void
  setLoading: () => void
}

interface State {
  data: FindCityResponse['data']
  open: boolean
  loading: boolean
  searchValue?: string
}

export class RegionForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      data: [],
      loading: false,
      open: false
    }
  }

  changeValue = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({...this.state, searchValue: event.currentTarget.value}, this.initQuery)
  }

  executeQuery = (): void => {
    setTimeout(() => {
      if (this.state.searchValue !== undefined && this.state.searchValue.length > 2) {
        FindCity.get({
          languageCode: this.props.language,
          searchValue: this.state.searchValue,
          useFirstHit: false
        })
          .then((response: FindCityResponse) =>
            this.setState({
              data: response.data,
              loading: false,
              open: true
            })
          )
          .catch(() => {
            this.setState({
              data: [],
              loading: false,
              open: false
            })
          })
      } else {
        this.setState({
          data: [],
          loading: false,
          open: false
        })
      }
    }, 1500)
  }

  initQuery = (): void => {
    if (!this.state.loading) {
      this.setState(
        {
          ...this.state,
          loading: true
        },
        this.executeQuery
      )
    }
  }

  setOpen = (state: boolean): void => {
    if (state && this.state.data.length === 0) {
      return
    }
    this.setState({
      ...this.state,
      open: state
    })
  }

  switchCoordinates = (
    _event: SyntheticEvent<Element, Event>,
    prototype: FindCityResponse['data'][0] | null
  ): void => {
    if (prototype === null) {
      return
    }
    this.props.setLoading()
    const href = `${window.location.href.split('?')[0]}?lat=${prototype.coordinates.latitude}&lon=${
      prototype.coordinates.longitude
    }`
    window.location.replace(href)
  }

  override render(): JSX.Element {
    return (
      <Paper
        elevation={3}
        sx={{
          aspectRatio: '1/1 !important',
          backgroundImage: 'url(/img/map.jpg)',
          backgroundPosition: 'center',
          backgroundPositionX: 'center',
          backgroundPositionY: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundRepeatX: 'no-repeat',
          backgroundRepeatY: 'no-repeat',
          backgroundSize: 'cover',
          height: '100%',
          marginRight: '0px'
        }}
      >
        <Container
          component="div"
          sx={{
            backgroundColor: 'rgb(182, 197, 213)',
            padding: '0.75rem 1.25rem'
          }}
        >
          <Typography
            component="h3"
            className="cardHeader"
            sx={{
              fontSize: '1.3125rem !important'
            }}
          >
            {this.props.strings?.regionForm.heading}
          </Typography>
        </Container>
        <Container
          sx={{
            display: 'block',
            justifyContent: 'center',
            marginTop: '10%',
            textAlign: 'center'
          }}
        >
          <Autocomplete
            open={this.state.open}
            sx={{textAlign: 'center'}}
            color="#FFF"
            onOpen={(): void => {
              this.setOpen(true)
            }}
            onClose={(): void => {
              this.setOpen(false)
            }}
            isOptionEqualToValue={(
              option: FindCityResponse['data'][number],
              value: FindCityResponse['data'][number]
            ): boolean =>
              option.coordinates.latitude === value.coordinates.latitude &&
              option.coordinates.longitude === value.coordinates.longitude
            }
            getOptionLabel={(option: FindCityResponse['data'][number]): string =>
              `${option.name}, ${option.region.code}, ${option.country.name}`
            }
            onChange={this.switchCoordinates}
            options={this.state.data}
            loading={this.state.loading}
            renderInput={(params): JSX.Element => (
              <TextField
                {...params}
                onChange={this.changeValue}
                style={{backgroundColor: '#FFF'}}
                placeholder={
                  this.props.location?.name !== undefined
                    ? `${this.props.location.name}, ${this.props.location.region.code}, ${this.props.location.country.name}`
                    : this.props.strings?.regionForm.textfieldPlaceholder
                }
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {this.state.loading ? <CircularProgress color="inherit" size={20} /> : null}
                    </React.Fragment>
                  )
                }}
              />
            )}
          />
          <Button
            color="primary"
            size="large"
            variant="contained"
            startIcon={<LocationOnIcon />}
            sx={{marginTop: '25%'}}
            onClick={this.useCurrentPosition}
          >
            {this.props.strings?.regionForm.myLocationButton}
          </Button>
        </Container>
      </Paper>
    )
  }

  useCurrentPosition = (): void => {
    this.props.setLoading()
    navigator.geolocation.getCurrentPosition(
      (pos) => {
        const href = `${window.location.href.split('?')[0]}?lat=${pos.coords.latitude}&lon=${
          pos.coords.longitude
        }`
        window.location.replace(href)
      },
      (_error) => {
        this.props.setError({
          message: this.props.strings?.error.generalMessage,
          title: this.props.strings?.error.title
        })
      },
      {enableHighAccuracy: false, maximumAge: Infinity, timeout: 5000}
    )
  }
}
