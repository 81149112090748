import {CircularProgress, Container, Grid, Typography} from '@mui/material'
import React from 'react'
import {Helmet} from 'react-helmet'
import {CityForCoordinates, CityForCoordinatesResponse} from '../client/CityForCoordinates'
import {Language, Languages, LanguagesResponse} from '../client/Languages'
import {Message} from '../client/Messages'
import {Strings, StringsResponse} from '../client/Strings'
import {TrendsForLocation, TrendsForLocationResponse} from '../client/TrendsForLocation'
import {TrendsWorldwideResponse, WorldwideTrends} from '../client/TrendsWorldwide'
import {DataCard} from '../components/datacard'
import {Footer} from '../components/footer'
import {Header} from '../components/header'
import {Messages} from '../components/messages'
import {RegionForm} from '../components/regionForm'
import {LanguageCodeConverter, LanguageCodes, TextDirection} from '../lib/LanguageCodes'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {}

interface State {
  complete: boolean
  error?: {
    message: string
    title?: string
  }
  language?: Language
  languageCode: LanguageCodes
  languages?: LanguagesResponse['languages']
  messages: Message[]
  strings?: StringsResponse
  lat?: string
  loadingState: number
  lon?: string
  localTrends?: TrendsForLocationResponse
  location?: CityForCoordinatesResponse
  textDirection?: TextDirection
  worldwideTrends?: TrendsWorldwideResponse
}
export class Main extends React.Component<Props, State> {
  override state: State

  constructor(props: React.PropsWithoutRef<Props>) {
    super(props)

    const languageCode = LanguageCodeConverter.stringToEnum(location.pathname.split('/')[1] ?? 'en')
    this.state = {
      complete: false,
      languageCode,
      languages: undefined,
      lat:
        new URLSearchParams(window.location.search).get('lat') === null
          ? undefined
          : (new URLSearchParams(window.location.search).get('lat') as string),
      loadingState: 0,
      localTrends: undefined,
      location: undefined,
      lon:
        new URLSearchParams(window.location.search).get('lon') === null
          ? undefined
          : (new URLSearchParams(window.location.search).get('lon') as string),
      messages: [],
      strings: undefined,
      textDirection: undefined,
      worldwideTrends: undefined
    }
  }

  override componentDidMount(): void {
    this.getLanguageInfos()
  }

  getLanguageInfos = (): void => {
    Languages.withTranslation({
      languageCode: this.state.languageCode
    })
      .then((value) => {
        const matchingLanguage = value.languages
          .filter((entry) => {
            return this.state.languageCode === entry.isoCode
          })
          .pop()
        this.setState(
          {
            ...this.state,
            language: matchingLanguage,
            languages: value.languages,
            loadingState: this.state.loadingState + 20,
            textDirection: matchingLanguage?.textDirection ?? TextDirection.LTR
          },
          this.getStrings
        )
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err)
        this.setError({
          message:
            this.state.strings?.error.queryData ??
            'An error occurred while querying the data. Please try again later.'
        })
      })
  }

  getLocalTrends = (lat?: string, lon?: string): void => {
    if (lat !== undefined && lon !== undefined) {
      TrendsForLocation.get({
        languageCode: this.state.languageCode,
        latitude: parseFloat(lat),
        limit: 18,
        longitude: parseFloat(lon)
      })
        .then((trends) => {
          this.setState(
            {
              ...this.state,
              loadingState: this.state.loadingState + 20,
              localTrends: trends
            },
            this.isComplete
          )
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err)
          this.setState(
            {
              ...this.state,
              loadingState: this.state.loadingState + 20,
              localTrends: undefined
            },
            this.isComplete
          )
        })
    } else {
      this.setState({...this.state, loadingState: this.state.loadingState + 20}, this.isComplete)
    }
  }

  getMyLocation = (): void => {
    if (this.state.lat !== undefined && this.state.lon !== undefined) {
      CityForCoordinates.get({
        languageCode: this.state.languageCode,
        latitude: parseFloat(this.state.lat),
        longitude: parseFloat(this.state.lon)
      })
        .then((location) => {
          this.setState(
            {
              ...this.state,
              loadingState: this.state.loadingState + 20,
              location
            },
            () => {
              return this.getLocalTrends(this.state.lat, this.state.lon)
            }
          )
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err)
          this.setError({
            message:
              this.state.strings?.error.queryData ??
              'An error occurred while querying the data. Please try again later.'
          })
        })
    } else {
      this.setState({...this.state, loadingState: this.state.loadingState + 20}, () => {
        return this.getLocalTrends(this.state.lat, this.state.lon)
      })
    }
  }

  getStrings = (): void => {
    Strings.forLanguage({
      languageCode: this.state.languageCode
    })
      .then((strings) => {
        this.setState(
          {
            ...this.state,
            loadingState: this.state.loadingState + 20,
            strings
          },
          this.getWorldwideTrends
        )
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err)
        this.setError({
          message:
            this.state.strings?.error.queryData ??
            'An error occurred while querying the data. Please try again later.'
        })
      })
  }

  getWorldwideTrends = (): void => {
    WorldwideTrends.get({
      limit: 18
    })
      .then((trends) => {
        this.setState(
          {
            ...this.state,
            loadingState: this.state.loadingState + 20,
            worldwideTrends: trends
          },
          this.getMyLocation
        )
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err)
        this.setError({
          message:
            this.state.strings?.error.queryData ??
            'An error occurred while querying the data. Please try again later.'
        })
      })
  }

  isComplete = (): void => {
    this.setState({
      ...this.state,
      complete: this.state.loadingState >= 100
    })
  }

  setError = (args?: {message?: string; title?: string}): void => {
    this.setState({
      ...this.state,
      error: {
        message: args?.message ?? this.state.strings?.error.queryData ?? 'Error',
        title: args?.title ?? this.state.strings?.error.title
      }
    })
  }

  setLoading = (): void => {
    this.setState({
      ...this.state,
      complete: false
    })
  }

  override render(): JSX.Element {
    if (!this.state.complete || this.state.strings === undefined) {
      return (
        <>
          <Header loadingState={this.state.loadingState} />
          <Container component="main" disableGutters={true}>
            <Container component="section" disableGutters={true}>
              <Messages error={this.state.error} messages={this.state.messages} />
              {this.state.error === undefined && (
                <>
                  <CircularProgress size={100} value={this.state.loadingState} />
                </>
              )}
            </Container>
          </Container>
        </>
      )
    } else {
      return (
        <>
          <Header
            languageCode={this.state.languageCode}
            language={this.state.language}
            languages={this.state.languages}
            loadingState={this.state.loadingState}
            strings={this.state.strings}
          />
          <Container component="main">
            <Messages error={this.state.error} messages={this.state.messages} />
            <Container
              component="section"
              disableGutters={true}
              sx={{marginLeft: 'auto', marginRight: 'auto', marginTop: '40px'}}
            >
              <Typography component="h2" className="heading">
                {this.state.strings.heading}
              </Typography>
              <Typography component="p" className="leadText">
                {this.state.strings.leadText}
              </Typography>
            </Container>
            <Grid
              container
              spacing={3}
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{marginTop: '44px'}}
            >
              <Grid item xs={11} md={6} lg={5}>
                <RegionForm
                  language={this.state.languageCode}
                  location={this.state.location}
                  setError={this.setError.bind(this)}
                  setLoading={this.setLoading.bind(this)}
                  strings={this.state.strings}
                />
              </Grid>
              {this.state.localTrends?.city !== undefined && (
                <Grid item xs={11} md={6} lg={5}>
                  <DataCard
                    language={this.state.languageCode}
                    strings={this.state.strings}
                    data={this.state.localTrends.city.trends.data}
                    image="/img/city.png"
                    title={this.state.localTrends.city.name}
                  />
                </Grid>
              )}
              {this.state.localTrends !== undefined && (
                <Grid item xs={11} md={6} lg={5}>
                  <DataCard
                    language={this.state.languageCode}
                    strings={this.state.strings}
                    data={this.state.localTrends.country.trends.data}
                    image={`/img/flags/${this.state.localTrends.country.code.toLowerCase()}.png`}
                    title={this.state.localTrends.country.name}
                  />
                </Grid>
              )}
              <Grid item xs={11} md={6} lg={5}>
                {this.state.worldwideTrends !== undefined && (
                  <DataCard
                    language={this.state.languageCode}
                    strings={this.state.strings}
                    data={this.state.worldwideTrends.data}
                    image="/img/flags/ww.png"
                    title={this.state.strings.worldwide.heading}
                  />
                )}
              </Grid>
            </Grid>
          </Container>
          <Footer language={this.state.languageCode} strings={this.state.strings} />

          <Helmet
            htmlAttributes={{
              dir: this.state.textDirection?.toLowerCase() ?? 'ltr',
              lang: this.state.languageCode
            }}
          >
            <title>{`trendytag # ${this.state.strings.heading}`}</title>
            <meta name="description" content={this.state.strings.leadText} />
            <meta name="twitter:title" content={`trendytag # ${this.state.strings.heading}`} />
          </Helmet>
        </>
      )
    }
  }
}
