import SendIcon from '@mui/icons-material/Send'
import {
  Alert,
  AlertTitle,
  Button,
  CircularProgress,
  Container,
  TextField,
  Typography
} from '@mui/material'
import React from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import {Helmet} from 'react-helmet'
import {ContactForm} from '../client/ContactForm'
import {Language, Languages, LanguagesResponse} from '../client/Languages'
import {Message, MessageType} from '../client/Messages'
import {Strings, StringsResponse} from '../client/Strings'
import {Footer} from '../components/footer'
import {Header} from '../components/header'
import {Messages} from '../components/messages'
import {GlobalConstants} from '../lib/GlobalConstants'
import {LanguageCodeConverter, LanguageCodes, TextDirection} from '../lib/LanguageCodes'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {}

interface State {
  complete: boolean
  data: {
    captcha?: string
    email: {
      valid: boolean
      value: string
    }
    message: {
      valid: boolean
      value: string
    }
    name: {
      valid: boolean
      value: string
    }
    ready: boolean
    subject: {
      valid: boolean
      value: string
    }
  }
  error?: {
    message: string
    title?: string
  }
  language?: Language
  languageCode: LanguageCodes
  languages?: LanguagesResponse['languages']
  messages: Message[]
  strings?: StringsResponse
  loadingState: number
  submitted: boolean
  textDirection?: TextDirection
}

export class Contact extends React.Component<Props, State> {
  override state: State

  constructor(props: Props) {
    super(props)
    const languageCode = LanguageCodeConverter.stringToEnum(location.pathname.split('/')[1] ?? 'en')
    const messages: Message[] = []

    this.state = {
      complete: false,
      data: {
        captcha: undefined,
        email: {
          valid: true,
          value: ''
        },
        message: {
          valid: true,
          value: ''
        },
        name: {
          valid: true,
          value: ''
        },
        ready: false,
        subject: {
          valid: true,
          value: ''
        }
      },
      languageCode,
      languages: undefined,
      loadingState: 0,
      messages,
      strings: undefined,
      submitted: false,
      textDirection: undefined
    }
  }

  clearData(): void {
    this.setState({
      ...this.state,
      data: {
        captcha: undefined,
        email: {
          valid: true,
          value: ''
        },
        message: {
          valid: true,
          value: ''
        },
        name: {
          valid: true,
          value: ''
        },
        ready: false,
        subject: {
          valid: true,
          value: ''
        }
      }
    })
  }

  override componentDidMount(): void {
    this.getLanguageInfos()
  }

  changeMail = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        email: {
          ...this.state.data.email,
          value: event.currentTarget.value
        }
      }
    })
  }

  changeMessage = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        message: {
          ...this.state.data.message,
          value: event.currentTarget.value
        }
      }
    })
  }

  changeName = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        name: {
          ...this.state.data.name,
          value: event.currentTarget.value
        }
      }
    })
  }

  changeSubject = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        subject: {
          ...this.state.data.subject,
          value: event.currentTarget.value
        }
      }
    })
  }

  checkMail = (): void => {
    this.setState(
      {
        ...this.state,
        data: {
          ...this.state.data,
          email: {
            ...this.state.data.email,
            valid: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
              this.state.data.email.value
            )
          }
        }
      },
      this.handleFormValidity
    )
  }

  checkMessage = (): void => {
    this.setState(
      {
        ...this.state,
        data: {
          ...this.state.data,
          message: {
            ...this.state.data.message,
            valid: this.state.data.message.value.length > 19
          }
        }
      },
      this.handleFormValidity
    )
  }

  checkName = (): void => {
    this.setState(
      {
        ...this.state,
        data: {
          ...this.state.data,
          name: {
            ...this.state.data.name,
            valid: this.state.data.name.value.length > 2
          }
        }
      },
      this.handleFormValidity
    )
  }

  checkSubject = (): void => {
    this.setState(
      {
        ...this.state,
        data: {
          ...this.state.data,
          subject: {
            ...this.state.data.subject,
            valid: this.state.data.subject.value.length > 2
          }
        }
      },
      this.handleFormValidity
    )
  }

  getLanguageInfos = (): void => {
    Languages.withTranslation({
      languageCode: this.state.languageCode
    })
      .then((value) => {
        const matchingLanguage = value.languages
          .filter((entry) => {
            return this.state.languageCode === entry.isoCode
          })
          .pop()
        this.setState(
          {
            ...this.state,
            language: matchingLanguage,
            languages: value.languages,
            loadingState: this.state.loadingState + 50,
            textDirection: matchingLanguage?.textDirection ?? TextDirection.LTR
          },
          this.getStrings
        )
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err)
        this.setError({
          message:
            this.state.strings?.error.queryData ??
            'An error occurred while querying the data. Please try again later.'
        })
      })
  }

  getStrings = (): void => {
    Strings.forLanguage({
      languageCode: this.state.languageCode
    })
      .then((strings) => {
        this.setState(
          {
            ...this.state,
            loadingState: this.state.loadingState + 50,
            strings
          },
          this.isComplete
        )

        if (
          this.state.languageCode !== LanguageCodes.DE &&
          this.state.languageCode !== LanguageCodes.EN
        ) {
          this.state.messages.push({
            content: strings.contactForm.leadText,
            priority: 2,
            type: MessageType.WARNING
          })
        }
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err)
        this.setError({
          message:
            this.state.strings?.error.queryData ??
            'An error occurred while querying the data. Please try again later.'
        })
      })
  }

  handleFormValidity = (): void => {
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        ready:
          this.state.data.captcha !== undefined &&
          this.state.data.email.valid &&
          this.state.data.message.valid &&
          this.state.data.name.valid &&
          this.state.data.subject.valid &&
          this.state.data.email.value !== '' &&
          this.state.data.message.value !== '' &&
          this.state.data.name.value !== '' &&
          this.state.data.subject.value !== ''
      }
    })
  }

  handleSubmit = (event: React.FormEvent): void => {
    event.preventDefault()
    this.setState({
      ...this.state,
      complete: false,
      error: undefined,
      submitted: false
    })

    ContactForm.submit({
      captcha: this.state.data.captcha ?? '',
      copy: true,
      email: this.state.data.email.value,
      language: this.state.languageCode,
      message: this.state.data.message.value,
      name: this.state.data.name.value,
      subject: this.state.data.subject.value
    })
      .then(() => {
        this.setState(
          {
            ...this.state,
            complete: true,
            error: undefined,
            submitted: true
          },
          () => {
            this.clearData()
            const form = document.getElementsByTagName('form')[0]
            if (form !== undefined) {
              form.reset()
            }
          }
        )
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err)
        this.setState({
          ...this.state,
          complete: true,
          submitted: false
        })
        this.setError({
          message: this.state.strings?.error.submitData,
          title: this.state.strings?.error.title
        })
      })
  }

  isComplete = (): void => {
    this.setState({
      ...this.state,
      complete: this.state.loadingState >= 100
    })
  }

  resolveCaptcha = (token: string | null | undefined): void => {
    if (token === null || token === undefined) {
      this.setState(
        {
          ...this.state,
          data: {
            ...this.state.data,
            captcha: undefined
          }
        },
        this.handleFormValidity
      )
    } else {
      this.setState(
        {
          ...this.state,
          data: {
            ...this.state.data,
            captcha: token
          }
        },
        this.handleFormValidity
      )
    }
  }

  override render(): JSX.Element {
    if (!this.state.complete || this.state.strings === undefined) {
      return (
        <>
          <Header loadingState={this.state.loadingState} />
          <Container component="main" disableGutters={true}>
            <Container component="section" disableGutters={true}>
              <Messages error={this.state.error} messages={this.state.messages} />
              {this.state.error === undefined && (
                <>
                  <CircularProgress size={100} value={this.state.loadingState} />
                </>
              )}
            </Container>
          </Container>
        </>
      )
    } else {
      return (
        <>
          <Header
            languageCode={this.state.languageCode}
            language={this.state.language}
            languages={this.state.languages}
            loadingState={this.state.loadingState}
            strings={this.state.strings}
          />
          <Container component="main">
            <Messages error={this.state.error} messages={this.state.messages} />
            {this.state.submitted && (
              <Alert style={{margin: '10px'}} severity="success">
                <AlertTitle sx={{textAlign: 'left'}}>
                  {this.state.strings.contactForm.success.title}
                </AlertTitle>
                <Typography component="span">
                  {this.state.strings.contactForm.success.message}
                </Typography>
              </Alert>
            )}
            <Container component="section" sx={{marginTop: '40px'}}>
              <Typography className="heading" component="h2">
                {this.state.strings.nav.contact}
              </Typography>
            </Container>
            <Container>
              <Container component="form" sx={{justifyContent: 'center'}}>
                <TextField
                  error={!this.state.data.name.valid}
                  helperText={
                    !this.state.data.name.valid
                      ? this.state.strings.contactForm.error.atLeastThreeLetters
                      : ''
                  }
                  label={this.state.strings.contactForm.name}
                  name="name"
                  onBlur={this.checkName}
                  onChange={this.changeName}
                  required
                  sx={{margin: '20px', width: '85%'}}
                  type="text"
                />
                <TextField
                  error={!this.state.data.email.valid}
                  helperText={
                    !this.state.data.email.valid
                      ? this.state.strings.contactForm.error.validEmail
                      : ''
                  }
                  label={this.state.strings.contactForm.email}
                  name="email"
                  onBlur={this.checkMail}
                  onChange={this.changeMail}
                  required
                  sx={{margin: '20px', width: '85%'}}
                  type="email"
                />
                <TextField
                  error={!this.state.data.subject.valid}
                  helperText={
                    !this.state.data.subject.valid
                      ? this.state.strings.contactForm.error.atLeastThreeLetters
                      : ''
                  }
                  label={this.state.strings.contactForm.subject}
                  name="subject"
                  onBlur={this.checkSubject}
                  onChange={this.changeSubject}
                  required
                  sx={{margin: '20px', width: '85%'}}
                  type="text"
                />
                <TextField
                  error={!this.state.data.message.valid}
                  helperText={
                    !this.state.data.subject.valid
                      ? this.state.strings.contactForm.error.atLeastTwentyLetters
                      : ''
                  }
                  label={this.state.strings.contactForm.message}
                  multiline={true}
                  name="message"
                  onBlur={this.checkMessage}
                  onChange={this.changeMessage}
                  required
                  rows={3}
                  sx={{margin: '20px', width: '85%'}}
                  type="text"
                />
                {this.state.data.captcha === undefined && (
                  <ReCAPTCHA
                    onChange={this.resolveCaptcha}
                    sitekey={GlobalConstants.RecaptchaSitekey}
                    style={{justifyContent: 'center', marginBottom: '20px', marginTop: '20px'}}
                  />
                )}
                <Button
                  endIcon={<SendIcon />}
                  disabled={!this.state.data.ready}
                  onClick={this.handleSubmit}
                  size="large"
                  variant="contained"
                >
                  {this.state.strings.contactForm.submit}
                </Button>
              </Container>
            </Container>
          </Container>
          <Footer language={this.state.languageCode} strings={this.state.strings} />
          <Helmet
            htmlAttributes={{
              dir: this.state.textDirection?.toLowerCase() ?? 'ltr',
              lang: this.state.languageCode.toLowerCase()
            }}
          >
            <title>{`trendytag # ${this.state.strings.heading}`}</title>
            <meta name="description" content={this.state.strings.longDescription} />
            <meta name="twitter:title" content={`trendytag # ${this.state.strings.heading}`} />
          </Helmet>
        </>
      )
    }
  }

  setError = (args?: {message?: string; title?: string}): void => {
    this.setState({
      ...this.state,
      error: {
        message: args?.message ?? this.state.strings?.error.queryData ?? 'Error',
        title: args?.title ?? this.state.strings?.error.title
      }
    })
  }

  setLoading = (): void => {
    this.setState({
      ...this.state,
      complete: false
    })
  }
}
