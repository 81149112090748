import {ApolloClient, InMemoryCache} from '@apollo/client'
import {GlobalConstants} from './GlobalConstants'

export class GraphQL {
    static client = new ApolloClient({
        cache: new InMemoryCache(),
        defaultOptions: {
            query: {
                fetchPolicy: 'no-cache'
            }
        },
        headers: {
            'x-api-key': GlobalConstants.ApiKey
        },
        uri: GlobalConstants.ApiUrl
    })
}
