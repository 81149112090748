import {gql} from '@apollo/client'
import {GraphQL} from '../lib/GraphQL'
import {LanguageCodes} from '../lib/LanguageCodes'

export interface CityForCoordinatesRequest {
    languageCode?: LanguageCodes
    latitude: number
    longitude: number
}

export interface CityForCoordinatesResponse {
    name: string
    population: string
    coordinates: {
        latitude: number
        longitude: number
    }
    country: {
        code: LanguageCodes
        name: string
    }
    region: {
        code: string
        name: string
    }
}

const getCityForCoordinates = gql`
    query CityForCoordinates($languageCode: LanguageCodes!, $longitude: Float!, $latitude: Float!) {
        CityForCoordinates(
            languageCode: $languageCode
            longitude: $longitude
            latitude: $latitude
        ) {
            name
            population
            coordinates {
                latitude
                longitude
            }
            country {
                code
                name
            }
            region {
                code
                name
            }
        }
    }
`
export class CityForCoordinates {
    static async get(args: {
        languageCode?: LanguageCodes
        latitude: number
        longitude: number
    }): Promise<CityForCoordinatesResponse> {
        const response = await GraphQL.client.query<
            {CityForCoordinates: CityForCoordinatesResponse},
            CityForCoordinatesRequest
        >({
            query: getCityForCoordinates,
            variables: {
                languageCode: args.languageCode ?? LanguageCodes.EN,
                latitude: args.latitude,
                longitude: args.longitude
            }
        })
        if (response.errors !== undefined) {
            throw new Error(response.errors.join(', '))
        }
        return response.data.CityForCoordinates
    }
}
