import {gql} from '@apollo/client'
import {GraphQL} from '../lib/GraphQL'
import {LanguageCodes} from '../lib/LanguageCodes'

export interface ContactFormRequest {
    captcha: string
    copy: boolean
    email: string
    language: LanguageCodes
    message: string
    name: string
    subject: string
}

export interface ContactFormResponse {
    messageCopyId?: string
    messageId: string
}

const sendContactForm = gql`
    mutation ContactForm(
        $captcha: String!
        $copy: Boolean!
        $email: String!
        $language: LanguageCodes
        $message: String!
        $name: String!
        $subject: String!
    ) {
        ContactForm(
            args: {
                captcha: $captcha
                copy: $copy
                email: $email
                language: $language
                message: $message
                name: $name
                subject: $subject
            }
        ) {
            messageCopyId
            messageId
        }
    }
`

export class ContactForm {
    static async submit(form: ContactFormRequest): Promise<ContactFormResponse> {
        const response = await GraphQL.client.mutate<
            {ContactForm: ContactFormResponse},
            ContactFormRequest
        >({
            mutation: sendContactForm,
            variables: form
        })
        if (response.errors !== undefined) {
            throw new Error(response.errors.join(', '))
        }
        if (response.data === undefined || response.data === null) {
            throw new Error('ErrUndefinedOrNillData')
        }
        return response.data.ContactForm
    }
}
