import {gql} from '@apollo/client'
import {GraphQL} from '../lib/GraphQL'
import {LanguageCodes} from '../lib/LanguageCodes'

export interface StringsRequest {
    languageCode: LanguageCodes
}

export interface StringsResponse {
    contactForm: {
        captcha: string
        email: string
        error: {
            atLeastThreeLetters: string
            atLeastTwentyLetters: string
            validEmail: string
        }
        leadText: string
        message: string
        name: string
        subject: string
        submit: string
        success: {
            message: string
            title: string
        }
    }
    error: {
        generalMessage: string
        queryData: string
        submitData: string
        timeout: string
        title: string
    }
    footer: {
        allRightsReserved: string
    }
    heading: string
    keywords: string[]
    language: string
    languages: {
        ar: string
        de: string
        en: string
        es: string
        fr: string
        hi: string
        it: string
        ja: string
        pt: string
    }
    leadText: string
    legalNotes: {
        contactFormLink: string
        content: {
            html: string
        }
        germany: string
        offeredBy: string
    }
    longDescription: string
    nav: {
        contact: string
        language: string
        legalNotes: string
        settings: string
        share: string
    }
    notice: string
    regionForm: {
        heading: string
        myLocationButton: string
        textfieldPlaceholder: string
    }
    settings: {
        allowNotifications: string
    }
    shortDescription: string
    worldwide: {
        heading: string
    }
}

const getStrings = gql`
    query Strings($languageCode: LanguageCodes!) {
        Strings(languageCode: $languageCode) {
            heading
            keywords
            language
            leadText
            longDescription
            notice
            shortDescription
            contactForm {
                captcha
                email
                leadText
                message
                name
                subject
                submit
                error {
                    atLeastThreeLetters
                    atLeastTwentyLetters
                    validEmail
                }
                success {
                    message
                    title
                }
            }
            error {
                generalMessage
                queryData
                submitData
                timeout
                title
            }
            footer {
                allRightsReserved
            }
            languages {
                ar
                de
                en
                es
                fr
                hi
                it
                ja
                pt
            }
            legalNotes {
                contactFormLink
                germany
                offeredBy
                content {
                    html
                }
            }
            nav {
                contact
                language
                legalNotes
                settings
                share
            }
            regionForm {
                heading
                myLocationButton
                textfieldPlaceholder
            }
            settings {
                allowNotifications
            }
            worldwide {
                heading
            }
        }
    }
`

export class Strings {
    static async forLanguage(variables: StringsRequest): Promise<StringsResponse> {
        const response = await GraphQL.client.query<{Strings: StringsResponse}, StringsRequest>({
            query: getStrings,
            variables
        })
        if (response.errors !== undefined) {
            throw new Error(response.errors.join(', '))
        }
        return response.data.Strings
    }
}
