import FacebookIcon from '@mui/icons-material/Facebook'
import HomeIcon from '@mui/icons-material/Home'
import {Container, IconButton, Link, Typography} from '@mui/material'
import React from 'react'
import {StringsResponse} from '../client/Strings'

interface Props {
  language: string
  strings: StringsResponse
}
export class Footer extends React.Component<Props> {
  override render(): JSX.Element {
    return (
      <Container
        component="footer"
        sx={{
          backgroundColor: '#8e8c84',
          color: '#4b4c4d',
          padding: '40px 0'
        }}
      >
        <Container sx={{textAlign: 'center', width: '100%'}}>
          <Link
            // aria-label="Facebook"
            color="inerhit"
            href="https://www.facebook.com/trendytag.info"
            target="_blank"
            underline="none"
            // rel="noopener noreferrer"
          >
            <IconButton
              aria-label="Facebook"
              sx={{border: '1px solid #ccc', margin: '10px'}}
              size="large"
            >
              <FacebookIcon />
            </IconButton>
          </Link>
          {/* <IconButton
            aria-label="Instagram"
            sx={{border: '1px solid #ccc', margin: '10px'}}
            size="large"
          >
            <InstagramIcon />
          </IconButton>
          <IconButton
            aria-label="App Store"
            sx={{border: '1px solid #ccc', margin: '10px'}}
            size="large"
          >
            <AppleIcon />
          </IconButton>
          <IconButton
            aria-label="Play Store"
            sx={{border: '1px solid #ccc', margin: '10px'}}
            size="large"
          >
            <AndroidIcon />
          </IconButton> */}
        </Container>
        <ul>
          <li>
            <Link href={`/${this.props.language.toLowerCase()}`}>
              <IconButton aria-label="Home">
                <HomeIcon />
              </IconButton>
            </Link>
          </li>
          <li
            style={{
              display: 'inline-block',
              marginRight: '0.5rem',
              padding: '0 10px'
            }}
          >
            <Link
              color="inerhit"
              href={`/${this.props.language.toLowerCase()}/contact`}
              underline="none"
            >
              {this.props.strings.nav.contact}
            </Link>
          </li>
          <li
            style={{
              display: 'inline-block',
              padding: '0 10px'
            }}
          >
            <Link
              color="inerhit"
              href={`/${this.props.language.toLowerCase()}/legal`}
              underline="none"
            >
              {this.props.strings.nav.legalNotes}
            </Link>
          </li>
        </ul>
        <Typography
          className="copyright"
          style={{
            color: '#aaa',
            fontSize: '13px',
            marginBottom: '0',
            marginTop: '15px',
            textAlign: 'center'
          }}
        >
          {`#trendytag © ${new Date().getFullYear()} - ${
            this.props.strings.footer.allRightsReserved
          }`}
        </Typography>
      </Container>
    )
  }
}
