import {Container, Paper, Table, TableBody, TableCell, TableRow, Typography} from '@mui/material'
import React from 'react'
import {StringsResponse} from '../client/Strings'
import {LanguageCodes} from '../lib/LanguageCodes'

interface Props {
  data: {
    place: number
    name: string
  }[]
  image: string
  language: LanguageCodes
  strings: StringsResponse
  title: string
}

export class DataCard extends React.Component<Props> {
  renderList(): JSX.Element[] {
    const trends = this.props.data

    return trends.map((trend) => {
      return (
        <TableRow hover key={`${this.props.title.trim().toLowerCase()}-${trend.place}`}>
          <TableCell align="center" sx={{padding: '3px'}}>
            {trend.place === 1 && (
              <img src="/img/top.png" alt="1" style={{height: '24px', marginBottom: '-6px'}} />
            )}
            {trend.place !== 1 && <span>{trend.place}</span>}
          </TableCell>
          <TableCell align="center" sx={{padding: '3px'}}>
            <span>{trend.name}</span>
          </TableCell>
        </TableRow>
      )
    })
  }

  override render(): JSX.Element {
    return (
      <Paper
        elevation={3}
        sx={{
          aspectRatio: '1/1 !important',
          backgroundColor: '#fcf8e3',
          overflow: 'hidden'
        }}
      >
        <Container
          component="div"
          sx={{
            backgroundColor: 'rgb(182, 197, 213)',
            padding: '0.75rem 1.25rem'
          }}
        >
          <img
            src={this.props.image}
            alt={this.props.title}
            style={{
              display: 'inline',
              height: '25px',
              marginBottom: '-4px',
              marginRight: '15px',
              width: 'auto'
            }}
          />
          <Typography
            component="h3"
            className="cardHeader"
            sx={{
              display: 'inline',
              fontSize: '1.3125rem !important'
            }}
          >
            {this.props.title}
          </Typography>
        </Container>
        <Table sx={{width: '100%'}} aria-label="custom pagination table">
          <TableBody>{this.renderList()}</TableBody>
        </Table>
      </Paper>
    )
  }
}
