export enum MessageType {
    ERROR = 'error',
    INFO = 'info',
    WARNING = 'warning'
}

export interface Message {
    content: string
    priority: number
    title?: string
    type: MessageType
    validity?: {
        from: string
        to: string
    }
}

export interface MessagesResponse {
    messages: Message[]
}
