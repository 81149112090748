import {
  AppBar,
  Avatar,
  Box,
  Container,
  IconButton,
  LinearProgress,
  Link,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography
} from '@mui/material'
import React from 'react'
import {Helmet} from 'react-helmet'
import {Language, LanguagesResponse} from '../client/Languages'
import {StringsResponse} from '../client/Strings'
import {LanguageCodes} from '../lib/LanguageCodes'

interface Props {
  language?: Language
  languageCode?: LanguageCodes
  languages?: LanguagesResponse['languages']
  loadingState: number
  strings?: StringsResponse
}

interface State {
  anchorEl: HTMLElement | null
}

export class Header extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      anchorEl: null
    }
  }
  handleOpenMenu = (event: React.MouseEvent<HTMLElement>): void => {
    this.setState({...this.state, anchorEl: event.currentTarget})
  }

  handleCloseMenu = (): void => {
    this.setState({...this.state, anchorEl: null})
  }

  switchLanguage = (languageCode: LanguageCodes): void => {
    this.handleCloseMenu()
    window.location.replace(`/${languageCode.toLowerCase()}`)
  }

  renderLanguages = (): JSX.Element => {
    let elements: LanguagesResponse['languages'] = []

    if (this.props.languages !== undefined) {
      elements = this.props.languages
    }

    return (
      <ul>
        {' '}
        {elements.map((entry) => {
          const {flag, isoCode, isoLanguageName, nativeLanguageName, translatedLanguageName} = entry
          return (
            <li key={isoCode}>
              <a key={isoCode} href={'/' + isoCode.toLowerCase()}>
                <img alt={isoLanguageName} className="menuFlag" src={flag} />
                <span>
                  {nativeLanguageName} ({isoLanguageName} / {translatedLanguageName})
                </span>
              </a>
              <Helmet>
                <link
                  rel="alternate"
                  hrefLang={isoCode.toLowerCase()}
                  href={`${window.location.protocol}//${window.location.hostname}${
                    window.location.hostname === 'localhost' ? ':3000' : ''
                  }/${isoCode.toLowerCase()}`}
                />
              </Helmet>
            </li>
          )
        })}{' '}
      </ul>
    )
  }

  override render(): JSX.Element {
    return (
      <AppBar sx={{backgroundColor: '#8e8c84'}} position="fixed">
        {this.props.loadingState < 100 && (
          <LinearProgress variant="determinate" value={this.props.loadingState} />
        )}
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Typography
              sx={{
                color: '#000',
                flexGrow: 1,
                fontFamily: 'Inconsolata, monospace',
                fontSize: '25px',
                textTransform: 'lowercase !important'
              }}
              variant="h1"
            >
              <Link
                color="#000"
                href={`/${this.props.languageCode?.toLowerCase() ?? ''}`}
                underline="none"
              >
                #trendytag.info
              </Link>
            </Typography>
            {this.props.strings !== undefined && this.props.language !== undefined && (
              <Box sx={{flexGrow: 0}}>
                <Tooltip title={this.props.strings.nav.language}>
                  <IconButton onClick={this.handleOpenMenu} sx={{p: 0}}>
                    <Avatar
                      alt={this.props.language.nativeLanguageName}
                      src={this.props.language.flag}
                      sx={{height: '30px', width: '50px'}}
                      variant="rounded"
                    />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{mt: '45px'}}
                  id="menu-appbar"
                  anchorEl={this.state.anchorEl}
                  anchorOrigin={{
                    horizontal: 'right',
                    vertical: 'top'
                  }}
                  keepMounted
                  transformOrigin={{
                    horizontal: 'right',
                    vertical: 'top'
                  }}
                  open={Boolean(this.state.anchorEl)}
                  onClose={this.handleCloseMenu}
                >
                  {(this.props.languages ?? [])
                    .filter((language) => {
                      return language.isoCode !== this.props.language?.isoCode
                    })
                    .sort((a, b) => {
                      return a.isoLanguageName > b.isoLanguageName ? 1 : -1
                    })
                    .map((language) => (
                      <MenuItem
                        key={language.isoCode}
                        onClick={(): void => {
                          this.switchLanguage(language.isoCode)
                        }}
                      >
                        <Avatar
                          alt={language.nativeLanguageName}
                          src={language.flag}
                          sx={{height: '20px', marginRight: '15px', width: '33px'}}
                          variant="rounded"
                        />
                        <Typography textAlign="center">{`${language.nativeLanguageName} (${language.isoLanguageName}/${language.translatedLanguageName})`}</Typography>
                      </MenuItem>
                    ))}
                </Menu>
              </Box>
            )}
          </Toolbar>
        </Container>
      </AppBar>
    )
  }
}
