import {gql} from '@apollo/client'
import {GraphQL} from '../lib/GraphQL'

export interface TrendsWorldwideRequest {
    limit?: number
}

export interface TrendsWorldwideResponse {
    count: number
    data: {
        place: number
        name: string
    }[]
}

const getWorldwideTrends = gql`
    query TrendsWorldwide {
        TrendsWorldwide {
            count
            data {
                place
                name
            }
        }
    }
`

const getWorldwideTrendsWithLimit = gql`
    query TrendsWorldwide($limit: Int) {
        TrendsWorldwide(limit: $limit) {
            count
            data {
                place
                name
            }
        }
    }
`

export class WorldwideTrends {
    static async get(variables: TrendsWorldwideRequest): Promise<TrendsWorldwideResponse> {
        let queryData
        if (variables.limit !== undefined) {
            queryData = {query: getWorldwideTrendsWithLimit, variables}
        } else {
            queryData = {query: getWorldwideTrends}
        }

        const response = await GraphQL.client.query<
            {TrendsWorldwide: TrendsWorldwideResponse},
            TrendsWorldwideRequest
        >(queryData)
        if (response.errors !== undefined) {
            throw new Error(response.errors.join(', '))
        }
        return response.data.TrendsWorldwide
    }
}
