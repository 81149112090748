import {gql} from '@apollo/client'
import {GraphQL} from '../lib/GraphQL'
import {LanguageCodes} from '../lib/LanguageCodes'

export interface FindCityRequest {
    languageCode: LanguageCodes
    searchValue: string
    useFirstHit: boolean
}

export interface FindCityResponse {
    count: number
    data: {
        name: string
        population: string
        coordinates: {
            latitude: number
            longitude: number
        }
        country: {
            code: string
            name: string
        }
        region: {
            code: string
            name: string
        }
    }[]
}

const findCity = gql`
    query FindCity($languageCode: LanguageCodes!, $searchValue: String!, $useFirstHit: Boolean!) {
        FindCity(
            languageCode: $languageCode
            searchValue: $searchValue
            useFirstHit: $useFirstHit
        ) {
            count
            data {
                name
                population
                coordinates {
                    latitude
                    longitude
                }
                country {
                    code
                    name
                }
                region {
                    code
                    name
                }
            }
        }
    }
`

export class FindCity {
    static async get(args: {
        languageCode: LanguageCodes
        searchValue: string
        useFirstHit: boolean
    }): Promise<FindCityResponse> {
        const response = await GraphQL.client.query<{FindCity: FindCityResponse}, FindCityRequest>({
            query: findCity,
            variables: {
                languageCode: args.languageCode,
                searchValue: args.searchValue,
                useFirstHit: args.useFirstHit
            }
        })
        if (response.errors !== undefined) {
            throw new Error(response.errors.join(', '))
        }
        return response.data.FindCity
    }
}
