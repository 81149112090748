export enum LanguageCodes {
    AR = 'AR',
    DE = 'DE',
    EN = 'EN',
    ES = 'ES',
    FR = 'FR',
    HI = 'HI',
    IT = 'IT',
    JA = 'JA',
    PT = 'PT'
}

export enum TextDirection {
    LTR = 'LTR',
    RTL = 'RTL'
}

export class LanguageCodeConverter {
    static stringToEnum(stringValue: string): LanguageCodes {
        switch (stringValue) {
            case 'ar':
                return LanguageCodes.AR
            case 'de':
                return LanguageCodes.DE
            case 'en':
                return LanguageCodes.EN
            case 'es':
                return LanguageCodes.ES
            case 'fr':
                return LanguageCodes.FR
            case 'hi':
                return LanguageCodes.HI
            case 'it':
                return LanguageCodes.IT
            case 'ja':
                return LanguageCodes.JA
            case 'pt':
                return LanguageCodes.PT
            default:
                throw new Error('ErrMissingLaguageCodeConverterValue')
        }
    }

    static stringToEnumOrUndefined(stringValue: string): LanguageCodes | undefined {
        switch (stringValue) {
            case 'ar':
                return LanguageCodes.AR
            case 'de':
                return LanguageCodes.DE
            case 'en':
                return LanguageCodes.EN
            case 'es':
                return LanguageCodes.ES
            case 'fr':
                return LanguageCodes.FR
            case 'hi':
                return LanguageCodes.HI
            case 'it':
                return LanguageCodes.IT
            case 'ja':
                return LanguageCodes.JA
            case 'pt':
                return LanguageCodes.PT
            default:
                return undefined
        }
    }

    static enumToStringOrUndefined(stringValue?: LanguageCodes): string | undefined {
        if (stringValue === undefined) {
            return undefined
        }
        return LanguageCodeConverter.enumToString(stringValue)
    }

    static enumToString(stringValue: LanguageCodes): string {
        switch (stringValue) {
            case LanguageCodes.AR:
                return 'ar'
            case LanguageCodes.DE:
                return 'de'
            case LanguageCodes.EN:
                return 'en'
            case LanguageCodes.ES:
                return 'es'
            case LanguageCodes.FR:
                return 'fr'
            case LanguageCodes.HI:
                return 'hi'
            case LanguageCodes.IT:
                return 'it'
            case LanguageCodes.JA:
                return 'ja'
            case LanguageCodes.PT:
                return 'pt'
            default:
                throw new Error('ErrMissingLaguageCodeConverterValue')
        }
    }
}

export class TextDirectionConverter {
    static stringToEnum(stringValue: 'ltr' | 'rtl'): TextDirection {
        switch (stringValue) {
            case 'ltr':
                return TextDirection.LTR
            case 'rtl':
                return TextDirection.RTL
            default:
                throw new Error('ErrMissingLaguageCodeConverterValue')
        }
    }

    static enumToString(stringValue: LanguageCodes): string {
        switch (stringValue) {
            case LanguageCodes.AR:
                return 'ar'
            case LanguageCodes.DE:
                return 'de'
            case LanguageCodes.EN:
                return 'en'
            case LanguageCodes.ES:
                return 'es'
            case LanguageCodes.FR:
                return 'fr'
            case LanguageCodes.HI:
                return 'hi'
            case LanguageCodes.IT:
                return 'it'
            case LanguageCodes.JA:
                return 'ja'
            case LanguageCodes.PT:
                return 'pt'
            default:
                throw new Error('ErrMissingLaguageCodeConverterValue')
        }
    }
}
