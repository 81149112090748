export class GlobalConstants {
    static get ApiKey(): string {
        if (process.env['REACT_APP_API_KEY'] === undefined) {
            throw new Error('ErrMissingEnvApiKey')
        }
        return process.env['REACT_APP_API_KEY']
    }

    static get ApiUrl(): string {
        if (process.env['REACT_APP_API_URL'] === undefined) {
            throw new Error('ErrMissingEnvApiUrl')
        }
        return `https://${process.env['REACT_APP_API_URL']}/`
    }

    static get BackendStage(): string {
        if (process.env['REACT_APP_BACKEND_STAGE'] === undefined) {
            throw new Error('ErrMissingEnvBackendStage')
        }
        return process.env['REACT_APP_BACKEND_STAGE']
    }

    static get RecaptchaSitekey(): string {
        if (process.env['REACT_APP_RECAPTCHA_SITEKEY'] === undefined) {
            throw new Error('ErrMissingEnvRecaptchaSitekey')
        }
        return process.env['REACT_APP_RECAPTCHA_SITEKEY']
    }
}
