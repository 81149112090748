import {gql} from '@apollo/client'
import {GraphQL} from '../lib/GraphQL'
import {LanguageCodes} from '../lib/LanguageCodes'

export interface TrendsForLocationRequest {
    languageCode: LanguageCodes
    latitude: number
    limit?: number
    longitude: number
}

export interface TrendsForLocationResponse {
    city?: {
        name: string
        trends: {
            count: number
            data: {
                place: number
                name: string
            }[]
        }
    }
    country: {
        code: string
        name: string
        trends: {
            count: number
            data: {
                place: number
                name: string
            }[]
        }
    }
}

const getTrendsForLocation = gql`
    query TrendsForLocation($languageCode: LanguageCodes!, $longitude: Float!, $latitude: Float!) {
        TrendsForLocation(languageCode: $languageCode, longitude: $longitude, latitude: $latitude) {
            city {
                name
                trends {
                    count
                    data {
                        place
                        name
                    }
                }
            }
            country {
                code
                name
                trends {
                    count
                    data {
                        place
                        name
                    }
                }
            }
        }
    }
`

const getTrendsForLocationWithLimit = gql`
    query TrendsForLocation(
        $languageCode: LanguageCodes!
        $longitude: Float!
        $latitude: Float!
        $limit: Int
    ) {
        TrendsForLocation(
            languageCode: $languageCode
            longitude: $longitude
            latitude: $latitude
            limit: $limit
        ) {
            city {
                name
                trends {
                    count
                    data {
                        place
                        name
                    }
                }
            }
            country {
                code
                name
                trends {
                    count
                    data {
                        place
                        name
                    }
                }
            }
        }
    }
`

export class TrendsForLocation {
    static async get(variables: TrendsForLocationRequest): Promise<TrendsForLocationResponse> {
        let queryData
        if (variables.limit !== undefined) {
            queryData = {query: getTrendsForLocationWithLimit, variables}
        } else {
            queryData = {query: getTrendsForLocation, variables}
        }

        const response = await GraphQL.client.query<
            {TrendsForLocation: TrendsForLocationResponse},
            TrendsForLocationRequest
        >(queryData)
        if (response.errors !== undefined) {
            throw new Error(response.errors.join(', '))
        }
        return response.data.TrendsForLocation
    }
}
