import EmailIcon from '@mui/icons-material/Email'
import FaxIcon from '@mui/icons-material/Fax'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone'
import {CircularProgress, Container, Link, Typography} from '@mui/material'
import React from 'react'
import {Helmet} from 'react-helmet'
import {Language, Languages, LanguagesResponse} from '../client/Languages'
import {Message, MessageType} from '../client/Messages'
import {Strings, StringsResponse} from '../client/Strings'
import {Footer} from '../components/footer'
import {Header} from '../components/header'
import {Messages} from '../components/messages'
import {LanguageCodeConverter, LanguageCodes, TextDirection} from '../lib/LanguageCodes'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {}

interface State {
  complete: boolean
  error?: {
    message: string
    title?: string
  }
  language?: Language
  languageCode: LanguageCodes
  languages?: LanguagesResponse['languages']
  messages: Message[]
  strings?: StringsResponse
  loadingState: number
  textDirection?: TextDirection
}

export class Legal extends React.Component<Props, State> {
  override state: State
  constructor(props: Props) {
    super(props)
    const languageCode = LanguageCodeConverter.stringToEnum(location.pathname.split('/')[1] ?? 'en')
    this.state = {
      complete: false,
      languageCode,
      loadingState: 0,
      messages: []
    }
  }

  override componentDidMount(): void {
    this.getLanguageInfos()
  }

  getLanguageInfos = (): void => {
    Languages.withTranslation({
      languageCode: this.state.languageCode
    })
      .then((value) => {
        const matchingLanguage = value.languages
          .filter((entry) => {
            return this.state.languageCode === entry.isoCode
          })
          .pop()
        this.setState(
          {
            ...this.state,
            language: matchingLanguage,
            languages: value.languages,
            loadingState: this.state.loadingState + 50,
            textDirection: matchingLanguage?.textDirection ?? TextDirection.LTR
          },
          this.getStrings
        )
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err)
        this.setError({
          message:
            this.state.strings?.error.queryData ??
            'An error occurred while querying the data. Please try again later.'
        })
      })
  }

  getStrings = (): void => {
    Strings.forLanguage({
      languageCode: this.state.languageCode
    })
      .then((strings) => {
        this.setState(
          {
            ...this.state,
            loadingState: this.state.loadingState + 50,
            strings
          },
          this.isComplete
        )

        if (
          this.state.languageCode !== LanguageCodes.DE &&
          this.state.languageCode !== LanguageCodes.EN
        ) {
          this.state.messages.push({
            content: strings.contactForm.leadText,
            priority: 2,
            type: MessageType.WARNING
          })
        }
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err)
        this.setError({
          message:
            this.state.strings?.error.queryData ??
            'An error occurred while querying the data. Please try again later.'
        })
      })
  }

  isComplete = (): void => {
    this.setState({
      ...this.state,
      complete: this.state.loadingState >= 100
    })
  }

  override render(): JSX.Element {
    if (!this.state.complete || this.state.strings === undefined) {
      return (
        <>
          <Header loadingState={this.state.loadingState} />
          <Container component="main" disableGutters={true}>
            <Container component="section" disableGutters={true}>
              <Messages error={this.state.error} messages={this.state.messages} />
              {this.state.error === undefined && (
                <>
                  <CircularProgress size={100} value={this.state.loadingState} />
                </>
              )}
            </Container>
          </Container>
        </>
      )
    } else {
      return (
        <>
          <Header
            languageCode={this.state.languageCode}
            language={this.state.language}
            languages={this.state.languages}
            loadingState={this.state.loadingState}
            strings={this.state.strings}
          />
          <Container component="main">
            <Messages error={this.state.error} messages={this.state.messages} />
            <Container component="section" sx={{marginTop: '40px'}}>
              <img
                src="/img/devtoday.png"
                id="devtodayLogo"
                alt="DEVtoday Logo"
                style={{maxWidth: '60%', width: '400px'}}
              />
              <Typography component="p">&nbsp;</Typography>
              <Typography className="heading" component="h2">
                {this.state.strings.nav.legalNotes}
              </Typography>
              <Typography component="p" sx={{marginTop: '20px'}}>
                {this.state.strings.legalNotes.offeredBy}
              </Typography>
              <Typography component="p" sx={{marginTop: '20px'}}>
                Sascha Mirow-Stanitzok
              </Typography>
              <Typography component="p" sx={{marginTop: '20px'}}>
                Johann-Hinrich-Fehrs-Str. 3<br />
                D - 24782 Büdelsdorf
                <br />
                {this.state.strings.legalNotes.germany}
              </Typography>
              <Typography component="p" sx={{marginTop: '20px'}}>
                <LocalPhoneIcon sx={{fontSize: '16px', marginBottom: '-2px', marginRight: '5px'}} />
                +49 4331 70 80 614
                <br />
                <FaxIcon sx={{fontSize: '16px', marginBottom: '-2px', marginRight: '5px'}} /> +49
                4331 70 80 615
                <br />
                <EmailIcon sx={{fontSize: '16px', marginBottom: '-2px', marginRight: '5px'}} />{' '}
                <Link href={`/${this.state.languageCode.toLowerCase()}/contact`}>
                  {this.state.strings.legalNotes.contactFormLink}
                </Link>
              </Typography>
            </Container>

            <Container
              component="section"
              sx={{paddingTop: '20px', textAlign: 'justify'}}
              dangerouslySetInnerHTML={{__html: this.state.strings.legalNotes.content.html}}
            ></Container>
          </Container>
          <Footer language={this.state.languageCode} strings={this.state.strings} />
          <Helmet
            htmlAttributes={{
              dir: this.state.textDirection?.toLowerCase() ?? 'ltr',
              lang: this.state.languageCode.toLowerCase()
            }}
          >
            <title>{`trendytag # ${this.state.strings.heading}`}</title>
            <meta name="description" content={this.state.strings.longDescription} />
            <meta name="twitter:title" content={`trendytag # ${this.state.strings.heading}`} />
          </Helmet>
        </>
      )
    }
  }

  setError = (args?: {message?: string; title?: string}): void => {
    this.setState({
      ...this.state,
      error: {
        message: args?.message ?? this.state.strings?.error.queryData ?? 'Error',
        title: args?.title ?? this.state.strings?.error.title
      }
    })
  }
}
