import {Alert, AlertTitle, Typography} from '@mui/material'
import React from 'react'
import {Message as ClientMessage} from '../client/Messages'

interface Props {
  error?: {
    message: string
    title?: string
  }
  messages: ClientMessage[]
}

export class Messages extends React.Component<Props> {
  override render(): JSX.Element {
    return (
      <>
        {this.props.error !== undefined && (
          <Alert style={{margin: '10px'}} severity="error">
            {this.props.error.title !== undefined && (
              <AlertTitle sx={{textAlign: 'left'}}>{this.props.error.title}</AlertTitle>
            )}
            <Typography component="span">{this.props.error.message}</Typography>
          </Alert>
        )}
        {this.props.error === undefined &&
          this.props.messages
            .filter((message) => {
              if (message.validity === undefined) {
                return true
              }
              const now = new Date().toISOString()
              return message.validity.from < now && now < message.validity.to
            })
            .sort((a, b) => {
              return a.priority > b.priority ? 1 : -1
            })
            .map((message, idx): JSX.Element => {
              return (
                <Alert style={{margin: '10px'}} key={`msg-${idx}`} severity={message.type}>
                  {message.title !== undefined && (
                    <AlertTitle sx={{textAlign: 'left'}}>{message.title}</AlertTitle>
                  )}
                  <Typography component="span">{message.content}</Typography>
                </Alert>
              )
            })}
      </>
    )
  }
}
